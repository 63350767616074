import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ClientesAPI from "../api/clientesAPI";
import VeiculosAPI from "../api/veiculosAPI";

const SelectAllVeiculos = ({ input }) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, []);

  function setValue() {
    const newDefaults = [];
    VeiculosAPI.getAllVeiculos().then((veiculos) => {
      for (const val of veiculos) {
        newDefaults.push({ value: val.id, label: val.matricula });
      }
      setData(newDefaults);
    });
  }

  useEffect(() => {
    if (input.veiculo_id != null && input.veiculo_id != "") {
      setDefaultValue({
        value: input.veiculo_id,
        label: input.veiculo.matricula,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.veiculo_id = select.value;
    setDefaultValue(select);
  }
  return (
    <Select
      components={animatedComponents}
      name="veiculo"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione veículo"
      value={defaultValue}
      onChange={(value) => handleInput(value)}
    />
  );
};

export default SelectAllVeiculos;
