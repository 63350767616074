import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import SelectPerfisAutorizacao from "../SelectPerfisAutorizacao";
import EmpregadosAPI from "../../api/empregadosAPI";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";

const EditEmpregadoModal = ({ showUpdateEmpregado, onHide, empregado }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="editarEmpregado">
      Editar
    </Button>
  );
  const [input, setInput] = useState({
    nome: empregado.nome,
    email: empregado.email,
    pwd: empregado.pwd,
    autorizacoes: empregado.autorizacoes,
    id: empregado.id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInput({
      nome: empregado.nome,
      email: empregado.email,
      pwd: empregado.pwd,
      autorizacoes: empregado.autorizacoes,
      id: empregado.id,
    });
  }, [empregado]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (
      input.nome !== "" &&
      input.email !== "" &&
      input.pwd !== "" &&
      input.autorizacoes !== "" &&
      input.id !== ""
    ) {
      EmpregadosAPI.updateEmpregado(input).then((res) => {
        console.log(res);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <LargeModal
        show={showUpdateEmpregado}
        onHide={onHide}
        title={"Editar " + empregado.nome}
        otherButtons={buttons}
      >
        <DXTForm
          id="editarEmpregado"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"nome"}
              type={"text"}
              placeholder={"Nome"}
              onChange={handleInput}
              label={"Nome"}
              errors={errors}
              defaultValue={empregado.nome}
            ></DXTInput>
            <DXTInput
              name={"email"}
              type={"email"}
              placeholder={"name@example.com"}
              onChange={handleInput}
              label={"Email"}
              errors={errors}
              defaultValue={empregado.email}
            ></DXTInput>
            <DXTInput
              name={"pwd"}
              type={"password"}
              placeholder={""}
              onChange={handleInput}
              label={"Password"}
              errors={errors}
              defaultValue={empregado.pwd}
            ></DXTInput>
          </Form.Group>
          <SelectPerfisAutorizacao input={input}></SelectPerfisAutorizacao>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default EditEmpregadoModal;
