import React from "react";
import Table from "react-bootstrap/Table";
import { diagxteamLightBlue } from "../config/colors";
const ResponsiveTable = ({ children }) => {
  return (
    <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
      <style type="text/css">
        {`
  .table-dxt th {
    background-color: ` +
          diagxteamLightBlue +
          `;
  .table-dxt thead{
   position:"sticky";
   top:"0";
  }
  }
  `}
      </style>

      <Table responsive bordered striped hover variant="dxt">
        {children}
      </Table>
    </div>
  );
};

export default ResponsiveTable;
