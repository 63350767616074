import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PaisesAPI from "../api/PaisesAPI";

const SelectPais = ({ input }) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, []);

  function setValue() {
    const newDefaults = [];
    PaisesAPI.getPaises().then((paises) => {
      for (const val of paises) {
        newDefaults.push({ value: val.id, label: val.nome });
      }
      setData(newDefaults);
    });
  }

  useEffect(() => {
    if (input.pais != null && input.pais.nome != "") {
      setDefaultValue({
        value: input.pais.id,
        label: input.pais.nome,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.id_pais = select.value;
    input.pais.nome = select.label;
    input.pais.id = select.value;

    setDefaultValue(select);
  }

  return (
    <Select
      components={animatedComponents}
      name="pais"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione país de origem"
      value={defaultValue}
      onChange={(value) => handleInput(value)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default SelectPais;
