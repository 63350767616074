const ENDPOINTS = {
  CSRF_TOKEN: "/sanctum/csrf-cookie",
  LOGIN: "/api/login",
  LOGOUT: "/api/logout",
  USER: "/api/user",
  EMPREGADOS: "/api/empregados",
  CLIENTES: "/api/clientes",
  TIPOS_TAREFA: "/api/tiposTarefa",
  PRODUTOS: "/api/produtos",
  TIPOS_SERVICO: "/api/tiposServico",
  ORDENS_SERVICO: "/api/ordensServico",
  VEICULOS: "/api/veiculos",
  MARCAS: "/api/marcas",
  MODELOS: "/api/modelos",
  VERSOES: "/api/versoes",
  PAISES: "/api/paises",
};

export default ENDPOINTS;
