import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const VeiculosAPI = {
  getVeiculos: async function () {
    var response = await api.request({
      url: ENDPOINTS.VEICULOS,
      method: "GET",
    });
    return response.data.data;
  },
  getAllVeiculos: async function () {
    var response = await api.request({
      url: ENDPOINTS.VEICULOS + "/all",
      method: "GET",
    });
    return response.data.data;
  },
  deleteVeiculo: async function (id) {
    var res = await api.delete(ENDPOINTS.VEICULOS + "/" + id);
    return res.data;
  },
  createVeiculo: async function (veiculo) {
    var res = await api.post(ENDPOINTS.VEICULOS, veiculo);
    return res.data;
  },
};

export default VeiculosAPI;
