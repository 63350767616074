import React, { useEffect, useRef, useState } from "react";
import ResponsiveTable from "../Table";
import EmpregadosAPI from "../../api/empregadosAPI";
import CreateEmpregadoModal from "../modals/CreateEmpregadoModal";
import Button from "react-bootstrap/Button";
import DeleteIcon from "../DeleteIcon";
import EditIcon from "../EditIcon";
import EditEmpregadoModal from "../modals/EditEmpregadoModal";

const EmpregadosList = () => {
  const [empregados, setEmpregados] = useState([]);
  const [showCreateEmpregado, setShowCreateEmpregado] = useState(false);
  const [showEditEmpregado, setShowEditEmpregado] = useState(false);
  const [selectedEmpregado, setSelectedEmpregado] = useState({
    nome: "none",
    autorizacoes: [{ perfil: "none" }],
  });

  useEffect(() => {
    updateEmpregados();
  }, [showCreateEmpregado, showEditEmpregado]);

  function updateEmpregados() {
    EmpregadosAPI.getEmpregados().then((empregados) => {
      setEmpregados(empregados);
    });
  }

  const deleteEmpregado = (id) => {
    EmpregadosAPI.deleteEmpregado(id).then((empregados) => {
      updateEmpregados();
    });
  };

  return (
    <div className="container">
      <br />
      <br />
      <Button className="add-btn" onClick={() => setShowCreateEmpregado(true)}>
        +
      </Button>
      <br />
      <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Perfis</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {empregados &&
            empregados.map((empregado) => {
              var auth = "";

              if (empregado.autorizacoes.length > 0) {
                for (const autorizacao of empregado.autorizacoes) {
                  auth += autorizacao.perfil + ";";
                }
              }
              return (
                <tr>
                  <td>{empregado.nome}</td>
                  <td>{empregado.email}</td>
                  <td>{auth}</td>
                  <td>
                    <DeleteIcon
                      title="Apagar empregado"
                      text={
                        "Tem a certeza que deseja apagar o empregado " +
                        empregado.nome +
                        "?"
                      }
                      deleteFunc={deleteEmpregado}
                      objId={empregado.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        setSelectedEmpregado(empregado);
                        setShowEditEmpregado(true);
                      }}
                      obj={empregado}
                    ></EditIcon>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <CreateEmpregadoModal
        showCreateEmpregado={showCreateEmpregado}
        onHide={() => {
          setShowCreateEmpregado(false);
        }}
      />
      <EditEmpregadoModal
        showUpdateEmpregado={showEditEmpregado}
        onHide={() => {
          setShowEditEmpregado(false);
        }}
        empregado={selectedEmpregado}
      />
    </div>
  );
};

export default EmpregadosList;
