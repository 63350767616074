import { Badge } from "react-bootstrap";

function BadgeList({ values, bg = "secondary" }) {
  return values.map((value) => {
    return (
      <>
        <Badge bg={bg}>{value}</Badge>{" "}
      </>
    );
  });
}

export default BadgeList;
