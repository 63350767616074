import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";
import ProdutosAPI from "../../api/produtosAPI";

const EditProdutoModal = ({ showEditProduto, onHide, produto }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="editarProduto">
      Editar
    </Button>
  );
  const [input, setInput] = useState({
    codigo: produto.codigo,
    descricao: produto.descricao,
    id: produto.id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInput({
      codigo: produto.codigo,
      descricao: produto.descricao,
      id: produto.id,
    });
  }, [produto]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.descricao !== "" && input.codigo !== "" && input.id !== "") {
      ProdutosAPI.updateProduto(input).then((res) => {
        console.log(res);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <LargeModal
        show={showEditProduto}
        onHide={onHide}
        title={"Editar produto " + produto.codigo}
        otherButtons={buttons}
      >
        <DXTForm
          id="editarProduto"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"codigo"}
              type={"text"}
              placeholder={"Código"}
              onChange={handleInput}
              label={"Código"}
              errors={errors}
              defaultValue={produto.codigo}
            ></DXTInput>
            <DXTInput
              name={"descricao"}
              type={"text"}
              placeholder={"Descrição"}
              onChange={handleInput}
              label={"Descrição"}
              errors={errors}
              defaultValue={produto.descricao}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default EditProdutoModal;
