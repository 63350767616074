import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import ModelosAPI from "../api/ModelosAPI";

const SelectModelo = ({ input, marca, onChange }) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, [marca]);

  function setValue() {
    const newDefaults = [];
    if (marca != "") {
      ModelosAPI.getModelosByMarca(marca).then((modelos) => {
        for (const val of modelos) {
          newDefaults.push({ value: val.id, label: val.modelo });
        }
        setData(newDefaults);
      });
    }
  }

  useEffect(() => {
    if (input.modelo != null && input.modelo != "") {
      setDefaultValue({
        value: input.id,
        label: input.modelo,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.modelo = select.modelo;
    input.id = select.id;
    onChange(select);
    setDefaultValue(select);
  }

  return (
    <CreatableSelect
      components={animatedComponents}
      name="modelo"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione modelo"
      value={defaultValue}
      isDisabled={marca == null || marca == ""}
      onChange={(value) => handleInput(value)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default SelectModelo;
