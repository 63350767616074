import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthAPI from "../api/authAPI";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const res = await AuthAPI.login(data.email, data.password);
      console.log(res);
      if (res != null) {
        setToken(res);
        const user = await AuthAPI.getUser();
        sessionStorage.setItem("user", JSON.stringify(user));
        navigate("/dashboard");
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = async () => {
    await AuthAPI.logout();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    navigate("/login");
  };

  const setToken = (token) => {
    sessionStorage.setItem("token", token);
  };

  const getToken = () => {
    return sessionStorage.getItem("token");
  };

  const getUser = () => {
    return JSON.parse(sessionStorage.getItem("user"));
  };

  return (
    <AuthContext.Provider value={{ loginAction, logOut, getToken, getUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
