import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import VersoesAPI from "../api/VersoesAPI";

const SelectVersao = ({ input, idModelo, onChange }) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, [idModelo]);

  function setValue() {
    const newDefaults = [];
    if (idModelo != null && idModelo != "") {
      VersoesAPI.getVersoesByModelo(idModelo).then((versoes) => {
        for (const val of versoes) {
          newDefaults.push({
            value: val.id,
            label: val.versao,
            tipoVeiculo: val.tipo_veiculo,
          });
        }
        setData(newDefaults);
      });
    }
  }

  useEffect(() => {
    if (input.versao != null && input.versao != "") {
      setDefaultValue({
        value: input.id,
        label: input.versao,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.versao = select.versao;
    input.id = select.id;
    input.tipo_veiculo = select.tipoVeiculo;
    onChange(select);

    setDefaultValue(select);
  }
  return (
    <CreatableSelect
      components={animatedComponents}
      name="versao"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione versão"
      value={defaultValue}
      isDisabled={input.modelo.modelo == null || input.modelo.modelo == ""}
      onChange={(value) => handleInput(value)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default SelectVersao;
