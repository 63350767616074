import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import Button from "react-bootstrap/Button";
import DeleteIcon from "../DeleteIcon";
import EditIcon from "../EditIcon";
import DXTInput from "../inputs/DXTInput";
import SelectCliente from "../SelectCliente";
import DXTForm from "../inputs/DXTForm";
import { Form } from "react-bootstrap";
import AddTarefaModal from "../modals/AddTarefaModal";
import BadgeList from "../BadgeList";
import EditTarefaModal from "../modals/EditTarefaModal";
import OrdensServicoAPI from "../../api/ordensServicoAPI";
import { useLocation, useNavigate } from "react-router-dom";
import SelectAllVeiculos from "../SelectAllVeiculos";

const OrdemServico = () => {
  const { state } = useLocation();
  let navigate = useNavigate();

  const [showAddTarefa, setShowAddTarefa] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [showEditTarefa, setShowEditTarefa] = useState(false);
  const [selectedTarefa, setSelectedTarefa] = useState({
    servicos: [],
    produtos: [],
    tipo_tarefa_id: "",
    tipo_tarefa: "",
  });

  const [input, setInput] = useState({
    descricao: "",
    user_cliente_id: "",
    tarefas: [],
    id: "",
  });
  useEffect(() => {
    if (state != null && state.ordem_servico != null) {
      console.log(state);
      input.id = state.ordem_servico.id;
      input.descricao = state.ordem_servico.descricao;
      input.user_cliente_id = state.ordem_servico.user_cliente_id;
      input.user_cliente = state.ordem_servico.user_cliente;
      input.veiculo = state.ordem_servico.veiculo;
      input.veiculo_id = state.ordem_servico.veiculo_id;

      input.tarefas = state.ordem_servico.tarefas;
      setForceUpdate(forceUpdate + 1);
    }
  }, []);
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    console.log("SUBMITING");
    console.log(input);
    e.preventDefault();
    if (
      input.descricao !== "" &&
      input.user_cliente_id !== "" &&
      input.tarefas.length > 0
    ) {
      if (input.id == "") {
        OrdensServicoAPI.createOrdemServico(input).then((ordensServico) => {
          console.log(ordensServico);
          navigate("/ordensServico");
        });
      } else if (input.id > 0) {
        OrdensServicoAPI.updateOrdemServico(input).then((ordensServico) => {
          console.log(ordensServico);
          navigate("/ordensServico");
        });
      }

      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const deleteTarefa = (tarefa) => {
    const index = input.tarefas.indexOf(tarefa);
    console.log("INDEX OF=" + index);
    if (index > -1) {
      // only splice array when item is found
      input.tarefas.splice(index, 1); // 2nd parameter means remove one item only
      setForceUpdate(forceUpdate + 1);
    }
  };
  return (
    <div className="container">
      <br />
      {input.id != "" ? (
        <h2>Editar Ordem de Serviço {input.id}</h2>
      ) : (
        <h2>Criar nova Ordem de Serviço</h2>
      )}
      <br />
      <DXTForm
        id="createOrdemServico"
        onSubmit={handleSubmit}
        setError={setErrors}
      >
        <DXTInput
          name={"descricao"}
          type={"text"}
          placeholder={"Descrição"}
          onChange={handleInput}
          label={"Descrição"}
          defaultValue={input.descricao}
          errors={errors}
        ></DXTInput>{" "}
        <Form.Label>
          <b>Cliente</b>
          <SelectCliente input={input}></SelectCliente>
        </Form.Label>
        <Form.Label>
          <b>Veículo</b>
          <SelectAllVeiculos input={input}></SelectAllVeiculos>
        </Form.Label>
        <br></br>
        <br></br>
        <Button className="add-btn" onClick={() => setShowAddTarefa(true)}>
          + Tarefa
        </Button>
        <br></br>
        <ResponsiveTable>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Serviços</th>
              <th>Produtos</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {input.tarefas.length > 0 &&
              input.tarefas.map((tarefa) => {
                return (
                  <tr>
                    <td>{tarefa.tipo_tarefa.codigo}</td>
                    <td>
                      <BadgeList
                        values={tarefa.servicos.map(
                          (servico) => servico.tipo_servico.codigo
                        )}
                      ></BadgeList>
                    </td>
                    <td>
                      <BadgeList
                        values={tarefa.produtos.map(
                          (produto) => produto.codigo
                        )}
                      ></BadgeList>
                    </td>
                    <td>
                      <DeleteIcon
                        title="Apagar tarefao"
                        text={
                          "Tem a certeza que deseja apagar a tarefa " +
                          tarefa.tipo_tarefa.codigo +
                          "?"
                        }
                        deleteFunc={deleteTarefa}
                        objId={tarefa}
                      ></DeleteIcon>
                      <EditIcon
                        editFunc={() => {
                          setSelectedTarefa(tarefa);
                          setShowEditTarefa(true);
                        }}
                        obj={tarefa}
                      ></EditIcon>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </ResponsiveTable>
        <Button variant="success" type="submit" form="createOrdemServico">
          Submeter
        </Button>
      </DXTForm>
      <AddTarefaModal
        showAddTarefa={showAddTarefa}
        onHide={() => {
          setShowAddTarefa(false);
        }}
        ordemServico={input}
      />
      <EditTarefaModal
        showEditTarefa={showEditTarefa}
        onHide={() => {
          setShowEditTarefa(false);
        }}
        tarefa={selectedTarefa}
      />
      <br />
      <br />
    </div>
  );
};

export default OrdemServico;
