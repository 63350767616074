import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import DeleteIcon from "../DeleteIcon";
import { Button } from "react-bootstrap";
import EditIcon from "../EditIcon";
import TiposServicoAPI from "../../api/tiposServicoAPI";
import CreateTipoServicoModal from "../modals/CreateTipoServicoModal";
import EditTipoServicoModal from "../modals/EditTipoServicoModal";

const TiposServicoList = () => {
  const [tiposServico, setTiposServico] = useState([]);
  const [showCreateTipoServico, setShowCreateTipoServico] = useState(false);
  const [showEditTipoServico, setShowEditTipoServico] = useState(false);
  const [selectedTipoServico, setSelectedTipoServico] = useState({
    codigo: "",
  });
  useEffect(() => {
    updateTiposServico();
  }, [showCreateTipoServico, showEditTipoServico]);

  function updateTiposServico() {
    TiposServicoAPI.getTiposServico().then((tiposServico) => {
      setTiposServico(tiposServico);
    });
  }
  const deleteTipoServico = (id) => {
    TiposServicoAPI.deleteTipoServico(id).then((tiposServico) => {
      updateTiposServico();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button
        className="add-btn"
        onClick={() => setShowCreateTipoServico(true)}
      >
        +
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tiposServico &&
            tiposServico.map((tipoServico) => {
              return (
                <tr>
                  <td>
                    <b>{tipoServico.codigo}</b>
                  </td>
                  <td>{tipoServico.descricao}</td>
                  <td>
                    <DeleteIcon
                      title="Apagar Tipo de serviço"
                      text={
                        "Tem a certeza que deseja apagar o tipo de serviço " +
                        tipoServico.codigo +
                        "?"
                      }
                      deleteFunc={deleteTipoServico}
                      objId={tipoServico.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        setSelectedTipoServico(tipoServico);
                        setShowEditTipoServico(true);
                      }}
                      obj={tipoServico}
                    ></EditIcon>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <CreateTipoServicoModal
        showCreateTipoServico={showCreateTipoServico}
        onHide={() => {
          setShowCreateTipoServico(false);
        }}
      />
      <EditTipoServicoModal
        showEditTipoServico={showEditTipoServico}
        onHide={() => {
          setShowEditTipoServico(false);
        }}
        tipoServico={selectedTipoServico}
      />
    </div>
  );
};

export default TiposServicoList;
