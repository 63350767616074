import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import PopupAlertWrapper from "./PopupAlert";

function EditIcon({ editFunc, obj }) {
  return (
    <>
      <Button size="sm" variant="warning" onClick={() => editFunc(obj)}>
        <PencilSquare className="align-middle"></PencilSquare>
      </Button>
    </>
  );
}

export default EditIcon;
