import { useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";

import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";
import TiposServicoAPI from "../../api/tiposServicoAPI";

const CreateTipoServicoModal = ({ showCreateTipoServico, onHide }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="createTipoServico">
      Criar
    </Button>
  );
  const [input, setInput] = useState({
    codigo: "",
    descricao: "",
  });
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.codigo !== "" && input.descricao !== "") {
      TiposServicoAPI.createTipoServico(input).then((tiposServico) => {
        console.log(tiposServico);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <LargeModal
        show={showCreateTipoServico}
        onHide={onHide}
        title="Criar Novo tipo de serviço"
        otherButtons={buttons}
      >
        <DXTForm
          id="createTipoServico"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"codigo"}
              type={"text"}
              placeholder={"Código"}
              onChange={handleInput}
              label={"Código"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"descricao"}
              type={"text"}
              placeholder={"Descrição"}
              onChange={handleInput}
              label={"Descrição"}
              errors={errors}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default CreateTipoServicoModal;
