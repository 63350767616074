import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import PopupAlertWrapper from "./PopupAlert";

function GenericIconWithAction({ children, action, title, text }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <PopupAlertWrapper>
        <Alert show={show}>
          <Alert.Heading>{title}</Alert.Heading>
          <p>{text}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                action();
                setShow(false);
              }}
            >
              Avançar
            </Button>{" "}
            <Button onClick={() => setShow(false)} variant="outline-danger ">
              Fechar
            </Button>
          </div>
        </Alert>
      </PopupAlertWrapper>
      <Button size="sm" onClick={() => setShow(true)}>
        {children}
      </Button>
    </>
  );
}

export default GenericIconWithAction;
