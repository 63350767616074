import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

function DXTInput({
  name,
  type,
  errors,
  defaultValue,
  label,
  placeholder,
  onChange,
}) {
  return (
    <>
      <FloatingLabel label={label} className="mb-3">
        <Form.Control
          name={name}
          type={type}
          placeholder={placeholder}
          autoFocus
          onChange={onChange}
          defaultValue={defaultValue}
          isInvalid={!!errors[name]}
        />
        <Form.Control.Feedback type="invalid">
          {!!errors[name] ? errors[name][0] : ""}
        </Form.Control.Feedback>
      </FloatingLabel>
    </>
  );
}

export default DXTInput;
