import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";
import TiposTarefaAPI from "../../api/tiposTarefaAPI";

const EditTipoTarefaModal = ({ showEditTipoTarefa, onHide, tipoTarefa }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="editarTipoTarefa">
      Editar
    </Button>
  );
  const [input, setInput] = useState({
    codigo: tipoTarefa.codigo,
    descricao: tipoTarefa.descricao,
    id: tipoTarefa.id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInput({
      codigo: tipoTarefa.codigo,
      descricao: tipoTarefa.descricao,
      id: tipoTarefa.id,
    });
  }, [tipoTarefa]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.descricao !== "" && input.codigo !== "" && input.id !== "") {
      TiposTarefaAPI.updateTipoTarefa(input).then((res) => {
        console.log(res);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <LargeModal
        show={showEditTipoTarefa}
        onHide={onHide}
        title={"Editar tipo de tarefa " + tipoTarefa.codigo}
        otherButtons={buttons}
      >
        <DXTForm
          id="editarTipoTarefa"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"codigo"}
              type={"text"}
              placeholder={"Código"}
              onChange={handleInput}
              label={"Código"}
              errors={errors}
              defaultValue={tipoTarefa.codigo}
            ></DXTInput>
            <DXTInput
              name={"descricao"}
              type={"text"}
              placeholder={"Descrição"}
              onChange={handleInput}
              label={"Descrição"}
              errors={errors}
              defaultValue={tipoTarefa.descricao}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default EditTipoTarefaModal;
