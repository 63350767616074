import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const TiposTarefaAPI = {
  getTiposTarefa: async function () {
    var response = await api.request({
      url: ENDPOINTS.TIPOS_TAREFA,
      method: "GET",
    });
    return response.data.data;
  },
  createTipoTarefa: async function (tipoTarefa) {
    var res = await api.post(ENDPOINTS.TIPOS_TAREFA, tipoTarefa);
    return res.data;
  },

  updateTipoTarefa: async function (tipoTarefa) {
    var res = await api.put(
      ENDPOINTS.TIPOS_TAREFA + "/" + tipoTarefa.id,
      tipoTarefa
    );
    return res.data;
  },
  deleteTipoTarefa: async function (id) {
    var res = await api.delete(ENDPOINTS.TIPOS_TAREFA + "/" + id);
    return res.data;
  },
};

export default TiposTarefaAPI;
