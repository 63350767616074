import { format } from "date-fns";

function FormattedDate(date) {
  var date = new Date(date.date);

  var formattedDate = format(date, "dd/MM/yyyy HH:mm:ss");

  return formattedDate;
}

export default FormattedDate;
