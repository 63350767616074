import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import DeleteIcon from "../DeleteIcon";
import OrdensServicoAPI from "../../api/ordensServicoAPI";
import FormattedDate from "../FormattedDate";
import { useNavigate } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import EditIcon from "../EditIcon";
import OrdemServicoEstadoBadge from "../OrdemServicoEstadoBadge";
import {
  CarFrontFill,
  Clipboard2Check,
  Clipboard2CheckFill,
  Wrench,
} from "react-bootstrap-icons";
import GenericIconWithAction from "../GenericIconWithAction";

const OrdensServicoList = () => {
  const [ordensServico, setOrdensServico] = useState([]);
  const [showCreateOrdemServico, setShowCreateOrdemServico] = useState(false);
  const [showEditOrdemServico, setShowEditOrdemServico] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    updateOrdensServico();
  }, [showCreateOrdemServico, showEditOrdemServico]);

  function updateOrdensServico() {
    OrdensServicoAPI.getOrdensServico().then((ordensServico) => {
      setOrdensServico(ordensServico);
    });
  }
  const deleteOrdemServico = (id) => {
    OrdensServicoAPI.deleteOrdemServico(id).then((ordemServico) => {
      updateOrdensServico();
    });
  };

  const iniciarOrdemServico = (id) => {
    OrdensServicoAPI.iniciarOrdemServico(id).then((ordemServico) => {
      updateOrdensServico();
    });
  };

  const terminarOrdemServico = (id) => {
    OrdensServicoAPI.terminarOrdemServico(id).then((ordemServico) => {
      updateOrdensServico();
    });
  };

  const entregarOrdemServico = (id) => {
    OrdensServicoAPI.entregarOrdemServico(id).then((ordemServico) => {
      updateOrdensServico();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button className="add-btn" onClick={() => navigate("/ordemServico")}>
        Nova Ordem de Serviço
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Cliente</th>
            <th>Veículo</th>
            <th>Estado</th>
            <th>Data criado</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {ordensServico &&
            ordensServico.map((ordemServico) => {
              return (
                <tr>
                  <td>
                    <b>{ordemServico.id}</b>
                  </td>
                  <td>{ordemServico.descricao}</td>
                  <td>{ordemServico.user_cliente.nome}</td>
                  <td>{ordemServico.veiculo?.matricula}</td>

                  <td>
                    <FormattedDate
                      date={ordemServico.created_at}
                    ></FormattedDate>
                  </td>
                  <td>
                    <OrdemServicoEstadoBadge
                      value={ordemServico.estado}
                    ></OrdemServicoEstadoBadge>
                  </td>
                  <td>
                    <DeleteIcon
                      title="Apagar ordem de serviço"
                      text={
                        "Tem a certeza que deseja apagar a ordem de serviço " +
                        ordemServico.id +
                        "?"
                      }
                      deleteFunc={deleteOrdemServico}
                      objId={ordemServico.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        navigate("/ordemServico", {
                          state: {
                            ordem_servico: ordemServico,
                          },
                        });
                      }}
                    ></EditIcon>{" "}
                    {(() => {
                      if (ordemServico.estado == "ABERTO") {
                        return (
                          <GenericIconWithAction
                            action={() => {
                              iniciarOrdemServico(ordemServico.id);
                            }}
                            title="Iniciar Ordem Serviço"
                            text="Tem a certeza que deseja iniciar trabalho nesta ordem de serviço?"
                          >
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 100 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-iniciar-` + ordemServico.id}
                                >
                                  Iniciar Ordem de Serviço
                                </Tooltip>
                              }
                            >
                              <Wrench className="align-middle"></Wrench>
                            </OverlayTrigger>
                          </GenericIconWithAction>
                        );
                      } else if (ordemServico.estado == "EM_PROGRESSO") {
                        return (
                          <GenericIconWithAction
                            action={() => {
                              terminarOrdemServico(ordemServico.id);
                            }}
                            title="Terminar Ordem Serviço"
                            text="Tem a certeza que deseja terminar trabalho nesta ordem de serviço?"
                          >
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 100 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-terminar-` + ordemServico.id}
                                >
                                  Terminar Ordem de Serviço
                                </Tooltip>
                              }
                            >
                              <Clipboard2CheckFill className="align-middle"></Clipboard2CheckFill>
                            </OverlayTrigger>
                          </GenericIconWithAction>
                        );
                      } else if (ordemServico.estado == "ENTREGA") {
                        return (
                          <GenericIconWithAction
                            action={() => {
                              entregarOrdemServico(ordemServico.id);
                            }}
                            title="Entregar Ordem Serviço"
                            text="Tem a certeza que deseja entregar esta ordem de serviço?"
                          >
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 100, hide: 100 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-entregar-` + ordemServico.id}
                                >
                                  Entregar Ordem de Serviço
                                </Tooltip>
                              }
                            >
                              <CarFrontFill className="align-middle"></CarFrontFill>
                            </OverlayTrigger>
                          </GenericIconWithAction>
                        );
                      } else {
                        return <div></div>;
                      }
                    })()}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
    </div>
  );
};

export default OrdensServicoList;
