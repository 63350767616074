import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const TiposServicoAPI = {
  getTiposServico: async function () {
    var response = await api.request({
      url: ENDPOINTS.TIPOS_SERVICO,
      method: "GET",
    });
    return response.data.data;
  },
  createTipoServico: async function (tipoServico) {
    var res = await api.post(ENDPOINTS.TIPOS_SERVICO, tipoServico);
    return res.data;
  },

  updateTipoServico: async function (tipoServico) {
    var res = await api.put(
      ENDPOINTS.TIPOS_SERVICO + "/" + tipoServico.id,
      tipoServico
    );
    return res.data;
  },
  deleteTipoServico: async function (id) {
    var res = await api.delete(ENDPOINTS.TIPOS_SERVICO + "/" + id);
    return res.data;
  },
};

export default TiposServicoAPI;
