import {
  ordemServicoAberto,
  ordemServicoEmProgresso,
  ordemServicoAguardaEntrega,
  ordemServicoTerminado,
} from "../config/colors";

export const optionsPerfis = [
  { value: "Administrador", label: "Administrador" },
  { value: "Técnico", label: "Técnico" },
  { value: "Gestor", label: "Gestor" },
];

export const optionsOrdemServicoEstado = [
  { value: "ABERTO", label: "Aberto", color: ordemServicoAberto },
  {
    value: "EM_PROGRESSO",
    label: "Em Progresso",
    color: ordemServicoEmProgresso,
  },
  {
    value: "ENTREGA",
    label: "Agurda Entrega",
    color: ordemServicoAguardaEntrega,
  },
  { value: "TERMINADO", label: "Terminado", color: ordemServicoTerminado },
];

export const optionsTipoVeiculo = [
  { value: "Ligeiro Passageiros", label: "Ligeiro Passageiros" },
  { value: "Ligeiro Mercadorias", label: "Ligeiro Mercadorias" },
  { value: "Pesado Passageiros", label: "Pesado Passageiros" },
  { value: "Pesado Mercadorias", label: "Pesado Mercadorias" },
  { value: "Moto", label: "Moto" },
  { value: "Outro", label: "Outro" },
];
