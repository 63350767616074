import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { optionsTipoVeiculo } from "../config/data";
import makeAnimated from "react-select/animated";

const SelectTipoVeiculo = ({ input, onChange }) => {
  const animatedComponents = makeAnimated();

  const data = optionsTipoVeiculo;
  const [defaults, setDefaults] = useState([]);

  useEffect(() => {
    setValue();
  }, [input.tipo_veiculo]);

  function setValue() {
    const newDefaults = [];
    for (const val of data) {
      if (input.tipo_veiculo == val.value) {
        newDefaults.push(val);
      }
    }
    setDefaults(newDefaults);
  }

  function handleInput(select) {
    input.tipo_veiculo = select.value;
    onChange(select);
    setValue();
  }
  return (
    <Select
      value={defaults}
      components={animatedComponents}
      name="tipoVeiculo"
      options={data}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Selecione tipo de veículo ..."
      onChange={(value) => handleInput(value)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default SelectTipoVeiculo;
