import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div>
      <CDBSidebar textColor="#fff" backgroundColor="#03454f">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            Sidebar
          </a>
        </CDBSidebarHeader>
        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/dashboard" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/empregados" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="users">Empregados</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/clientes" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="address-book">
                Clientes
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/tiposTarefa" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tasks">
                Tipos de Tarefa
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/produtos" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tasks">Produtos</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/tiposServico" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tasks">
                Tipos de Serviço
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/ordensServico" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tasks">
                Ordens de serviço
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/veiculos" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="car">Veículos</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 5px",
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
