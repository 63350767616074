import { Badge } from "react-bootstrap";
import { optionsOrdemServicoEstado } from "./../config/data";

function OrdemServicoEstadoBadge({ value }) {
  const option = optionsOrdemServicoEstado.find(
    (estado) => estado.value === value
  );

  const styles = () => ({
    customBadge: {
      backgroundColor: option.color,
      color: "white",
    },
  });
  return (
    <>
      <Badge bg="" style={{ backgroundColor: option.color }}>
        {option.label}
      </Badge>{" "}
    </>
  );
}

export default OrdemServicoEstadoBadge;
