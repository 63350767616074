import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import DXTInput from "../inputs/DXTInput";
import DXTForm from "../inputs/DXTForm";
import SelectMarca from "../SelectMarca";
import VeiculosAPI from "../../api/veiculosAPI";
import SelectModelo from "../SelectModelo";
import SelectVersao from "../SelectVersao";
import SelectTipoVeiculo from "../SelectTipoVeiculo";
import SelectPais from "../SelectPais";
import SelectVersaoMotorizacao from "../SelectVersaoMotorizacao";

const NewOrEditVeiculo = ({ showNeworEditVeiculo, onHide }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="newOrEditVeiculo">
      Avançar
    </Button>
  );
  const [input, setInput] = useState({
    id_pais: "",
    pais: {
      nome: "",
      id: "",
    },
    matricula: "",
    vin: "",
    ano: "",
    motorizacao: "",
    versao: {
      versao: "",
      id: "",
      modelo: {
        modelo: "",
        id: "",
        marca: {
          marca: "",
        },
      },
    },
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.matricula !== "" && input.vin !== "") {
      VeiculosAPI.createVeiculo(input).then((veiculo) => {
        console.log(veiculo);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const title = "Criar Novo Veículo";
  if (input.id != null) {
    title = "Editar Veículo";
  }

  return (
    <>
      <LargeModal
        show={showNeworEditVeiculo}
        onHide={onHide}
        title={title}
        otherButtons={buttons}
      >
        <DXTForm
          id="newOrEditVeiculo"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <b>Marca</b>
              <SelectMarca
                input={input.versao.modelo.marca}
                onChange={(select) => {
                  input.versao.modelo.marca.marca = select.value;
                  setInput((prev) => ({
                    ...prev,
                    versao: input.versao,
                  }));
                }}
              ></SelectMarca>
            </Form.Label>
            <Form.Label>
              <b>Modelo</b>
              <SelectModelo
                input={input.versao.modelo}
                marca={input.versao.modelo.marca.marca}
                onChange={(select) => {
                  input.versao.modelo.id = select.value;
                  input.versao.modelo.modelo = select.label;
                  setInput((prev) => ({
                    ...prev,
                    versao: input.versao,
                  }));
                }}
              ></SelectModelo>
            </Form.Label>
            <Form.Label>
              <b>Versão</b>
              <SelectVersao
                input={input.versao}
                idModelo={input.versao.modelo.id}
                onChange={(select) => {
                  input.versao.id = select.value;
                  input.versao.versao = select.label;
                  input.versao.tipo_veiculo = select.tipoVeiculo;

                  setInput((prev) => ({
                    ...prev,
                    versao: input.versao,
                  }));
                }}
              ></SelectVersao>
            </Form.Label>
            <Form.Label>
              <b>Tipo de Veículo</b>
              <SelectTipoVeiculo
                input={input.versao}
                onChange={(select) => {
                  input.versao.tipo_veiculo = select.value;
                  setInput((prev) => ({
                    ...prev,
                    versao: input.versao,
                  }));
                }}
              ></SelectTipoVeiculo>
            </Form.Label>
            <Form.Label>
              <b>País</b>
              <SelectPais input={input}></SelectPais>
            </Form.Label>
            <DXTInput
              name={"matricula"}
              type={"text"}
              placeholder={"Matrícula"}
              onChange={handleInput}
              label={"Matrícula"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"vin"}
              type={"text"}
              placeholder={"VIN"}
              onChange={handleInput}
              label={"VIN"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"ano"}
              type={"integer"}
              placeholder={"Ano"}
              onChange={handleInput}
              label={"Ano"}
              errors={errors}
            ></DXTInput>
            <Form.Label>
              <b>Motorização</b>
              <SelectVersaoMotorizacao
                input={input}
                idVersao={input.versao.id}
                onChange={(select) => {
                  setInput((prev) => ({
                    ...prev,
                    motorizacao: select.value,
                  }));
                }}
              ></SelectVersaoMotorizacao>
            </Form.Label>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default NewOrEditVeiculo;
