import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENVIRONMENT,
});
api.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;
  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  if (statusCode && (statusCode == 419 || statusCode == 401)) {
    sessionStorage.removeItem("token");
    window.history.pushState({}, "Login", "/login");
    window.location.reload();
  } else if (error.response?.data.message) {
    sessionStorage.setItem("errorMessage", error.response.data.message);
    sessionStorage.setItem(
      "errors",
      JSON.stringify(error.response.data.errors)
    );

    window.dispatchEvent(new Event("showError"));
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
api.interceptors.response.use((res) => {
  const statusCode = res.status;
  console.log(res);

  if (
    statusCode &&
    statusCode > 199 &&
    statusCode < 300 &&
    res.config.method != "get"
  ) {
    sessionStorage.setItem("successMessage", res.data.message);
    sessionStorage.setItem("errors", "{}");
    window.dispatchEvent(new Event("showSuccess"));
  }
  return res;
});

api.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
