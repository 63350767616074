import Cookies from "js-cookie";
import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const AuthAPI = {
  login: async function (email, password) {
    console.log("baseURL=" + api.baseUrl);
    console.log(process.env.REACT_APP_API_ENVIRONMENT);
    await this.refreshCSRF();

    const credentials = {
      email: email,
      password: password,
    };
    var res = await api.post(ENDPOINTS.LOGIN, credentials);
    return res.data.token;
  },
  refreshCSRF: async function () {
    Cookies.remove("XSRF-TOKEN");
    await api.request({
      url: ENDPOINTS.CSRF_TOKEN,
      method: "GET",
    });
    return Cookies.get("XSRF-TOKEN");
  },

  logout: async function () {
    await api.request({
      url: ENDPOINTS.LOGOUT,
      method: "POST",
    });
  },

  getUser: async function () {
    var response = await api.request({
      url: ENDPOINTS.USER,
      method: "GET",
    });
    return response.data.user;
  },
};

export default AuthAPI;
