import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const VersoesAPI = {
  getVersoesByModelo: async function (modeloId) {
    var response = await api.request({
      url: ENDPOINTS.VERSOES + "/byModelo/" + modeloId,
      method: "GET",
    });
    return response.data.data;
  },
  getMotorizacoesForVersao: async function (id) {
    var response = await api.request({
      url: ENDPOINTS.VERSOES + "/" + id + "/motorizacoes",
      method: "GET",
    });
    return response.data.data;
  },
};
export default VersoesAPI;
