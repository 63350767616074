import { useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import { ProdutosAPI } from "../../api/produtosAPI";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";

const CreateProdutoModal = ({ showCreateProduto, onHide }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="createProduto">
      Criar
    </Button>
  );
  const [input, setInput] = useState({
    codigo: "",
    descricao: "",
  });
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.codigo !== "" && input.descricao !== "") {
      ProdutosAPI.createProduto(input).then((produtos) => {
        console.log(produtos);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <LargeModal
        show={showCreateProduto}
        onHide={onHide}
        title="Criar Novo produto"
        otherButtons={buttons}
      >
        <DXTForm
          id="createProduto"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"codigo"}
              type={"text"}
              placeholder={"Código"}
              onChange={handleInput}
              label={"Código"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"descricao"}
              type={"text"}
              placeholder={"Descrição"}
              onChange={handleInput}
              label={"Descrição"}
              errors={errors}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default CreateProdutoModal;
