import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import Button from "react-bootstrap/Button";
import ClientesAPI from "../../api/clientesAPI";
import CreateClienteModal from "../modals/CreateClienteModal";
import DeleteIcon from "../DeleteIcon";
import EditClienteModal from "../modals/EditClienteModal";
import EditIcon from "../EditIcon";

const EmpregadosList = () => {
  const [clientes, setClientes] = useState([]);
  const [showCreateCliente, setShowCreateCliente] = useState(false);
  const [showEditCliente, setShowEditCliente] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState({
    nome: "none",
  });

  useEffect(() => {
    updateClientes();
  }, [showEditCliente, showCreateCliente]);

  function updateClientes() {
    ClientesAPI.getClientes().then((clientes) => {
      setClientes(clientes);
    });
  }

  const deleteCliente = (id) => {
    ClientesAPI.deleteCliente(id).then((clientes) => {
      updateClientes();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button className="add-btn" onClick={() => setShowCreateCliente(true)}>
        +
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Telemóvel</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {clientes &&
            clientes.map((cliente) => {
              return (
                <tr>
                  <td>{cliente.nome}</td>
                  <td>{cliente.email}</td>
                  <td>{cliente.telemovel}</td>
                  <td>
                    <DeleteIcon
                      title="Apagar cliente"
                      text={
                        "Tem a certeza que deseja apagar o cliente " +
                        cliente.nome +
                        "?"
                      }
                      deleteFunc={deleteCliente}
                      objId={cliente.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        setSelectedCliente(cliente);
                        setShowEditCliente(true);
                      }}
                      obj={cliente}
                    ></EditIcon>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <CreateClienteModal
        showCreateCliente={showCreateCliente}
        onHide={() => {
          setShowCreateCliente(false);
        }}
      />
      <EditClienteModal
        showUpdateCliente={showEditCliente}
        onHide={() => {
          setShowEditCliente(false);
        }}
        cliente={selectedCliente}
      />
    </div>
  );
};

export default EmpregadosList;
