import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import { ProdutosAPI } from "../../api/produtosAPI";
import DeleteIcon from "../DeleteIcon";
import { Button } from "react-bootstrap";
import EditIcon from "../EditIcon";
import CreateProdutoModal from "../modals/CreateProdutoModal";
import EditProdutoModal from "../modals/EditProdutoModal";

const ProdutosList = () => {
  const [produtos, setProdutos] = useState([]);
  const [showCreateProduto, setShowCreateProduto] = useState(false);
  const [showEditProduto, setShowEditProduto] = useState(false);
  const [selectedProduto, setSelectedProduto] = useState({
    codigo: "",
  });
  useEffect(() => {
    updateProdutos();
  }, [showCreateProduto, showEditProduto]);

  function updateProdutos() {
    ProdutosAPI.getProdutos().then((produtos) => {
      setProdutos(produtos);
    });
  }
  const deleteProduto = (id) => {
    ProdutosAPI.deleteProduto(id).then((produtos) => {
      updateProdutos();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button className="add-btn" onClick={() => setShowCreateProduto(true)}>
        +
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {produtos &&
            produtos.map((produto) => {
              return (
                <tr>
                  <td>
                    <b>{produto.codigo}</b>
                  </td>
                  <td>{produto.descricao}</td>
                  <td>
                    <DeleteIcon
                      title="Apagar Produto"
                      text={
                        "Tem a certeza que deseja apagar o produto " +
                        produto.codigo +
                        "?"
                      }
                      deleteFunc={deleteProduto}
                      objId={produto.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        setSelectedProduto(produto);
                        setShowEditProduto(true);
                      }}
                      obj={produto}
                    ></EditIcon>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <CreateProdutoModal
        showCreateProduto={showCreateProduto}
        onHide={() => {
          setShowCreateProduto(false);
        }}
      />
      <EditProdutoModal
        showEditProduto={showEditProduto}
        onHide={() => {
          setShowEditProduto(false);
        }}
        produto={selectedProduto}
      />
    </div>
  );
};

export default ProdutosList;
