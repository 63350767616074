import { useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import DXTForm from "../inputs/DXTForm";
import SelectTipoTarefa from "../SelectTipoTarefa";
import SelectServicos from "../SelectServicos";
import SelectProdutos from "../SelectProdutos";

const AddTarefaModal = ({ showAddTarefa, onHide, ordemServico }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="addTarefa">
      Adicionar
    </Button>
  );
  const [input, setInput] = useState({
    tipo_tarefa_id: "",
    servicos: [],
    produtos: [],
  });
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.tipo_tarefa_id !== "" && input.servicos !== "") {
      ordemServico.tarefas.push(JSON.parse(JSON.stringify(input)));
      console.log("Ordem de serviço:");
      console.log(ordemServico);
      onHide();
      setInput({ tipo_tarefa_id: "", servicos: [], produtos: [] });
      return;
    }
    alert("Por favor insira dados");
  };

  return (
    <>
      <LargeModal
        show={showAddTarefa}
        onHide={onHide}
        title="Adicionar Tarefa"
        otherButtons={buttons}
      >
        <DXTForm id="addTarefa" onSubmit={handleSubmit} setError={setErrors}>
          <Form.Label>
            {" "}
            Tipo de tarefa
            <SelectTipoTarefa input={input}></SelectTipoTarefa>
          </Form.Label>
          <br></br>
          <Form.Label>
            {" "}
            Serviços
            <SelectServicos input={input}></SelectServicos>
          </Form.Label>
          <Form.Label>
            {" "}
            Produtos
            <SelectProdutos input={input}></SelectProdutos>
          </Form.Label>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default AddTarefaModal;
