import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const OrdensServicoAPI = {
  getOrdensServico: async function () {
    var response = await api.request({
      url: ENDPOINTS.ORDENS_SERVICO,
      method: "GET",
    });
    return response.data.data;
  },

  deleteOrdemServico: async function (id) {
    var res = await api.delete(ENDPOINTS.ORDENS_SERVICO + "/" + id);
    return res.data;
  },
  createOrdemServico: async function (ordemServico) {
    var res = await api.post(ENDPOINTS.ORDENS_SERVICO, ordemServico);
    return res.data;
  },

  updateOrdemServico: async function (ordemServico) {
    var res = await api.put(
      ENDPOINTS.ORDENS_SERVICO + "/" + ordemServico.id,
      ordemServico
    );
    return res.data;
  },

  iniciarOrdemServico: async function (id) {
    var res = await api.post(ENDPOINTS.ORDENS_SERVICO + "/iniciar/" + id);
    return res.data;
  },
  terminarOrdemServico: async function (id) {
    var res = await api.post(ENDPOINTS.ORDENS_SERVICO + "/terminar/" + id);
    return res.data;
  },
  entregarOrdemServico: async function (id) {
    var res = await api.post(ENDPOINTS.ORDENS_SERVICO + "/entregar/" + id);
    return res.data;
  },
};

export default OrdensServicoAPI;
