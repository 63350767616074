import { useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import ClientesAPI from "../../api/clientesAPI";
import DXTInput from "../inputs/DXTInput";
import DXTForm from "../inputs/DXTForm";

const CreateClienteModal = ({ showCreateCliente, onHide }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="createCliente">
      Criar
    </Button>
  );
  const [input, setInput] = useState({
    nome: "",
    email: "",
    telemovel: "",
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.nome !== "" && input.email !== "") {
      ClientesAPI.createCliente(input).then((empregados) => {
        console.log(empregados);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <LargeModal
        show={showCreateCliente}
        onHide={onHide}
        title="Criar Novo Cliente"
        otherButtons={buttons}
      >
        <DXTForm
          id="createCliente"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"nome"}
              type={"text"}
              placeholder={"Nome"}
              onChange={handleInput}
              label={"Nome"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"email"}
              type={"email"}
              placeholder={"name@example.com"}
              onChange={handleInput}
              label={"Email"}
              errors={errors}
            ></DXTInput>
            <DXTInput
              name={"telemovel"}
              type={"text"}
              placeholder={"911 111 111"}
              onChange={handleInput}
              label={"Telemóvel"}
              errors={errors}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default CreateClienteModal;
