import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const ClientesAPI = {
  getClientes: async function () {
    var response = await api.request({
      url: ENDPOINTS.CLIENTES,
      method: "GET",
    });
    return response.data.data;
  },

  createCliente: async function (cliente) {
    var res = await api.post(ENDPOINTS.CLIENTES, cliente);
    return res.data;
  },
  updateCliente: async function (cliente) {
    var res = await api.put(ENDPOINTS.CLIENTES + "/" + cliente.id, cliente);
    return res.data;
  },

  deleteCliente: async function (id) {
    var res = await api.delete(ENDPOINTS.CLIENTES + "/" + id);
    return res.data;
  },
};

export default ClientesAPI;
