import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import { TiposTarefaAPI } from "../../api/tiposTarefaAPI";
import DeleteIcon from "../DeleteIcon";
import CreateTipoTarefaModal from "../modals/CreateTipoTarefaModal";
import EditTipoTarefaModal from "../modals/EditTipoTarefaModal";
import EditIcon from "../EditIcon";
import { Button } from "react-bootstrap";

const TiposTarefaList = () => {
  const [tiposTarefa, setTiposTarefa] = useState([]);
  const [showCreateTipoTarefa, setShowCreateTipoTarefa] = useState(false);
  const [showEditTipoTarefa, setShowEditTipoTarefa] = useState(false);
  const [selectedTipoTarefa, setSelectedTipoTarefa] = useState({
    codigo: "",
  });
  useEffect(() => {
    updateTiposTarefa();
  }, [showCreateTipoTarefa, showEditTipoTarefa]);

  function updateTiposTarefa() {
    TiposTarefaAPI.getTiposTarefa().then((tiposTarefa) => {
      setTiposTarefa(tiposTarefa);
    });
  }

  const deleteTipoTarefa = (id) => {
    TiposTarefaAPI.deleteTipoTarefa(id).then((tiposTarefa) => {
      updateTiposTarefa();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button className="add-btn" onClick={() => setShowCreateTipoTarefa(true)}>
        +
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tiposTarefa &&
            tiposTarefa.map((tipoTarefa) => {
              return (
                <tr>
                  <td>
                    <b>{tipoTarefa.codigo}</b>
                  </td>
                  <td>{tipoTarefa.descricao}</td>

                  <td>
                    {" "}
                    <DeleteIcon
                      title="Apagar Tipo de tarefa"
                      text={
                        "Tem a certeza que deseja apagar o tipo de tarefa " +
                        tipoTarefa.codigo +
                        "?"
                      }
                      deleteFunc={deleteTipoTarefa}
                      objId={tipoTarefa.id}
                    ></DeleteIcon>{" "}
                    <EditIcon
                      editFunc={() => {
                        setSelectedTipoTarefa(tipoTarefa);
                        setShowEditTipoTarefa(true);
                      }}
                      obj={tipoTarefa}
                    ></EditIcon>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <CreateTipoTarefaModal
        showCreateTipoTarefa={showCreateTipoTarefa}
        onHide={() => {
          setShowCreateTipoTarefa(false);
        }}
      />
      <EditTipoTarefaModal
        showEditTipoTarefa={showEditTipoTarefa}
        onHide={() => {
          setShowEditTipoTarefa(false);
        }}
        tipoTarefa={selectedTipoTarefa}
      />
    </div>
  );
};

export default TiposTarefaList;
