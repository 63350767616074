import React, { useEffect, useState } from "react";
import ResponsiveTable from "../Table";
import DeleteIcon from "../DeleteIcon";
import { Button } from "react-bootstrap";
import VeiculosAPI from "../../api/veiculosAPI";
import { useAuth } from "../../hooks/AuthProvider";
import NewOrEditVeiculo from "../modals/NewOrEditVeiculo";

const VeiculosList = () => {
  const auth = useAuth();
  const [veiculos, setVeiculos] = useState([]);
  const [showCreateVeiculo, setShowCreateVeiculo] = useState(false);
  const [showEditVeiculo, setShowEditVeiculo] = useState(false);
  const [selectedVeiculo, setSelectedVeiculo] = useState({
    codigo: "",
  });
  useEffect(() => {
    updateVeiculos();
  }, [showCreateVeiculo, showEditVeiculo]);

  function updateVeiculos() {
    VeiculosAPI.getVeiculos().then((veiculos) => {
      setVeiculos(veiculos);
    });
  }
  const deleteVeiculo = (id) => {
    VeiculosAPI.deleteVeiculo(id).then((veiculos) => {
      updateVeiculos();
    });
  };

  return (
    <div className="container">
      <br></br>
      <Button className="add-btn" onClick={() => setShowCreateVeiculo(true)}>
        +
      </Button>
      <br /> <br />
      <ResponsiveTable>
        <thead>
          <tr>
            <th>Modelo</th>
            <th>Matrícula</th>
            <th>VIN</th>
            <th>Ano</th>
            <th>Motorização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {veiculos &&
            veiculos.map((veiculo) => {
              return (
                <tr>
                  <td>
                    {veiculo.versao.modelo.marca.marca}{" "}
                    {veiculo.versao.modelo.modelo} {veiculo.versao.versao}
                  </td>
                  <td>{veiculo.matricula}</td>
                  <td>{veiculo.vin}</td>
                  <td>{veiculo.ano}</td>
                  <td>{veiculo.motorizacao}</td>
                  <td>
                    {" "}
                    {veiculo.id_user == auth.getUser().uid ? (
                      <DeleteIcon
                        title="Apagar veículo"
                        text={
                          "Tem a certeza que deseja apagar o veículo " +
                          veiculo.matricula +
                          "?"
                        }
                        deleteFunc={deleteVeiculo}
                        objId={veiculo.id}
                      ></DeleteIcon>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ResponsiveTable>
      <NewOrEditVeiculo
        showNeworEditVeiculo={showCreateVeiculo}
        onHide={() => {
          setShowCreateVeiculo(false);
        }}
      />
    </div>
  );
};

export default VeiculosList;
