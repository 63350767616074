import { useState } from "react";
import Toast from "react-bootstrap/Toast";

function SuccessMessage() {
  const [success, setSuccess] = useState();
  const [show, setShow] = useState(false);

  window.addEventListener("showSuccess", () => {
    var msg = sessionStorage.getItem("successMessage");
    setSuccess(msg);
    setShow(true);
  });
  return (
    <>
      <Toast
        bg="success"
        show={show}
        delay={10000}
        autohide
        onClose={() => setShow(false)}
      >
        <Toast.Header>
          <strong className="me-auto">Notificação!</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{success}</Toast.Body>
      </Toast>
    </>
  );
}

export default SuccessMessage;
