import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ClientesAPI from "../api/clientesAPI";

const SelectCliente = (input) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, []);

  function setValue() {
    const newDefaults = [];
    ClientesAPI.getClientes().then((clientes) => {
      for (const val of clientes) {
        newDefaults.push({ value: val.id, label: val.nome });
      }
      setData(newDefaults);
    });
  }

  useEffect(() => {
    if (
      input.input.user_cliente_id != null &&
      input.input.user_cliente_id != ""
    ) {
      setDefaultValue({
        value: input.input.user_cliente_id,
        label: input.input.user_cliente.nome,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.input.user_cliente_id = select.value;
    setDefaultValue(select);
  }
  return (
    <Select
      components={animatedComponents}
      name="cliente"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione cliente"
      value={defaultValue}
      onChange={(value) => handleInput(value)}
    />
  );
};

export default SelectCliente;
