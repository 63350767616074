import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import ClientesAPI from "../../api/clientesAPI";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";

const EditClienteModal = ({ showUpdateCliente, onHide, cliente }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="editarCliente">
      Editar
    </Button>
  );
  const [input, setInput] = useState({
    nome: cliente.nome,
    email: cliente.email,
    telemovel: cliente.telemovel,
    id: cliente.id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setInput({
      nome: cliente.nome,
      email: cliente.email,
      telemovel: cliente.telemovel,
      id: cliente.id,
    });
  }, [cliente]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.nome !== "" && input.email !== "" && input.id !== "") {
      ClientesAPI.updateCliente(input).then((res) => {
        console.log(res);
      });
      return;
    }
    alert("Por favor insira dados");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <LargeModal
        show={showUpdateCliente}
        onHide={onHide}
        title={"Editar " + cliente.nome}
        otherButtons={buttons}
      >
        <DXTForm
          id="editarCliente"
          onSubmit={handleSubmit}
          setError={setErrors}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <DXTInput
              name={"nome"}
              type={"text"}
              placeholder={"Nome"}
              onChange={handleInput}
              label={"Nome"}
              errors={errors}
              defaultValue={cliente.nome}
            ></DXTInput>
            <DXTInput
              name={"email"}
              type={"email"}
              placeholder={"name@example.com"}
              onChange={handleInput}
              label={"Email"}
              errors={errors}
              defaultValue={cliente.email}
            ></DXTInput>
            <DXTInput
              name={"telemovel"}
              type={"text"}
              placeholder={"911 111 111"}
              onChange={handleInput}
              label={"Telemóvel"}
              errors={errors}
              defaultValue={cliente.telemovel}
            ></DXTInput>
          </Form.Group>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default EditClienteModal;
