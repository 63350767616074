import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";

function PopupAlertWrapper({ children }) {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 50000,
        }}
      >
        {children}
      </div>
    </>
  );
}

export default PopupAlertWrapper;
