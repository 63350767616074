import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const ProdutosAPI = {
  getProdutos: async function () {
    var response = await api.request({
      url: ENDPOINTS.PRODUTOS,
      method: "GET",
    });
    return response.data.data;
  },
  createProduto: async function (produto) {
    var res = await api.post(ENDPOINTS.PRODUTOS, produto);
    return res.data;
  },

  updateProduto: async function (produto) {
    var res = await api.put(ENDPOINTS.PRODUTOS + "/" + produto.id, produto);
    return res.data;
  },
  deleteProduto: async function (id) {
    var res = await api.delete(ENDPOINTS.PRODUTOS + "/" + id);
    return res.data;
  },
};

export default ProdutosAPI;
