import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import MarcasAPI from "../api/MarcasAPI";

const SelectMarca = ({ input, onChange }) => {
  const animatedComponents = makeAnimated();

  const [data, setData] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  useEffect(() => {
    setValue();
  }, []);

  function setValue() {
    const newDefaults = [];
    MarcasAPI.getMarcas().then((marcas) => {
      for (const val of marcas) {
        newDefaults.push({ value: val.marca, label: val.marca });
      }
      setData(newDefaults);
    });
  }

  useEffect(() => {
    if (input.marca != null && input.marca != "") {
      setDefaultValue({
        value: input.marca,
        label: input.marca,
      });
    } else {
      setDefaultValue(null);
    }
  }, [data]);

  function handleInput(select) {
    input.marca = select.value;
    onChange(select);
    setDefaultValue(select);
  }
  console.log(input);

  return (
    <CreatableSelect
      components={animatedComponents}
      name="marca"
      options={data}
      className="basic-single"
      classNamePrefix="select"
      placeholder="Selecione marca"
      value={defaultValue}
      onChange={(value) => handleInput(value)}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default SelectMarca;
