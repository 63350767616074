import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { optionsPerfis } from "../config/data";
import makeAnimated from "react-select/animated";

const SelectPerfisAutorizacao = (input) => {
  const animatedComponents = makeAnimated();

  const data = optionsPerfis;
  const [defaults, setDefaults] = useState([]);

  useEffect(() => {
    setValue();
  }, [input]);

  function setValue() {
    const newDefaults = [];
    for (const val of data) {
      if (
        input.input.autorizacoes
          .map((autorizacao) => autorizacao.perfil)
          .includes(val.value)
      ) {
        newDefaults.push(val);
      }
    }
    setDefaults(newDefaults);
  }

  function handleInput(select) {
    var values = select.map((x) => x.value);
    const autorizacoes = [];
    for (const value of values) {
      const autorizacao = { perfil: value };
      autorizacoes.push(autorizacao);
    }
    input.input.autorizacoes = autorizacoes;
    setValue();
  }
  return (
    <Select
      value={defaults}
      isMulti
      components={animatedComponents}
      name="autorizacoes"
      options={data}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Selecione perfil(s)..."
      onChange={(value) => handleInput(value)}
    />
  );
};

export default SelectPerfisAutorizacao;
