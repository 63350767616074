import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const ModelosAPI = {
  getModelosByMarca: async function (marca) {
    var response = await api.request({
      url: ENDPOINTS.MODELOS + "/byMarca/" + marca,
      method: "GET",
    });
    return response.data.data;
  },
};
export default ModelosAPI;
