import React from "react";
import Sidebar from "./Sidebar"; // Import the sidebar component
import { Container, ToastContainer } from "react-bootstrap";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
const Layout = ({ children }) => {
  return (
    <div
      className="layout"
      style={{
        minWidth: 0,
        minHeight: "100vh",
        maxHeight: "100vh",
      }}
    >
      {/* Include the sidebar */}
      <Sidebar />

      {/* Main content area */}
      <div className="content" style={{ minWidth: 0 }}>
        {children}
      </div>
      <ToastContainer
        className="p-3"
        position="bottom-end"
        style={{ zIndex: 1000000 }}
      >
        <ErrorMessage></ErrorMessage>
        <SuccessMessage></SuccessMessage>
      </ToastContainer>

      {/* CSS for layout */}
      <style jsx>{`
        .layout {
          display: flex;
        }
        ,
        .content {
          mind-width: 0;
        }
      `}</style>
    </div>
  );
};

export default Layout;
