import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const PaisesAPI = {
  getPaises: async function () {
    var response = await api.request({
      url: ENDPOINTS.PAISES,
      method: "GET",
    });
    return response.data.data;
  },
};
export default PaisesAPI;
