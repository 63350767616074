import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated, { Input } from "react-select/animated";
import ProdutosAPI from "../api/produtosAPI";

const SelectProdutos = (input) => {
  const animatedComponents = makeAnimated();
  const [data, setData] = useState([]);
  const [defaults, setDefaults] = useState([]);

  useEffect(() => {
    ProdutosAPI.getProdutos().then((produtos) => {
      const newDefaults = [];
      for (const val of produtos) {
        newDefaults.push({ value: val.id, label: val.codigo });
      }
      setData(newDefaults);
    });
  }, [animatedComponents]);

  useEffect(() => {
    setValue();
  }, [data, input, animatedComponents]);

  function setValue() {
    const newDefaults = [];
    console.log("PRODUTOS INPUT");
    console.log(input);
    if (input.input.produtos != null && input.input.produtos.length > 0) {
      for (const val of data) {
        if (
          input.input.produtos.map((produto) => produto.id).includes(val.value)
        ) {
          newDefaults.push(val);
        }
      }
    }
    setDefaults(newDefaults);
  }

  function handleInput(select) {
    var values = select.map((x) => x);
    const produtos = [];
    for (const value of values) {
      const produto = {
        id: value.value,
        codigo: value.label,
      };
      produtos.push(produto);
    }
    input.input.produtos = produtos;
    setValue();
  }
  return (
    <Select
      value={defaults}
      isMulti
      components={animatedComponents}
      name="produtos"
      options={data}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Selecione produtos(s)..."
      onChange={(value) => handleInput(value)}
    />
  );
};

export default SelectProdutos;
