import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const EmpregadosAPI = {
  getEmpregados: async function () {
    var response = await api.request({
      url: ENDPOINTS.EMPREGADOS,
      method: "GET",
    });
    return response.data.data;
  },

  createEmpregado: async function (empregado) {
    var res = await api.post(ENDPOINTS.EMPREGADOS, empregado);
    return res.data;
  },

  updateEmpregado: async function (empregado) {
    var res = await api.put(
      ENDPOINTS.EMPREGADOS + "/" + empregado.id,
      empregado
    );
    return res.data;
  },

  deleteEmpregado: async function (id) {
    var res = await api.delete(ENDPOINTS.EMPREGADOS + "/" + id);
    return res.data;
  },
};

export default EmpregadosAPI;
