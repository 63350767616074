import { useState } from "react";
import Toast from "react-bootstrap/Toast";

function ErrorMessage() {
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  window.addEventListener("showError", () => {
    var errorMsg = sessionStorage.getItem("errorMessage");
    setError(errorMsg);
    setShow(true);
  });
  return (
    <>
      <Toast
        bg="danger"
        show={show}
        delay={10000}
        autohide
        onClose={() => setShow(false)}
      >
        <Toast.Header>
          <strong className="me-auto">Ocorreu um erro!</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{error}</Toast.Body>
      </Toast>
    </>
  );
}

export default ErrorMessage;
