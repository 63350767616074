import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import PopupAlertWrapper from "./PopupAlert";

function DeleteIcon({ title, text, deleteFunc, objId }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <PopupAlertWrapper>
        <Alert show={show} variant="danger  ">
          <Alert.Heading>{title}</Alert.Heading>
          <p>{text}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                deleteFunc(objId);
                setShow(false);
              }}
              variant="outline-danger "
            >
              Apagar
            </Button>{" "}
            <Button onClick={() => setShow(false)} variant="outline-danger ">
              Fechar
            </Button>
          </div>
        </Alert>
      </PopupAlertWrapper>
      <Button size="sm" variant="danger" onClick={() => setShow(true)}>
        <TrashFill className="align-middle"></TrashFill>
      </Button>
    </>
  );
}

export default DeleteIcon;
