import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LargeModal from "./LargeModal";
import { Button } from "react-bootstrap";
import SelectPerfisAutorizacao from "../SelectPerfisAutorizacao";
import EmpregadosAPI from "../../api/empregadosAPI";
import DXTForm from "../inputs/DXTForm";
import DXTInput from "../inputs/DXTInput";
import SelectServicos from "../SelectServicos";
import SelectTipoTarefa from "../SelectTipoTarefa";
import SelectProdutos from "../SelectProdutos";

const EditTarefaModal = ({ showEditTarefa, onHide, tarefa }) => {
  const buttons = (
    <Button variant="primary" type="submit" form="editarTarefa">
      Editar
    </Button>
  );
  const [input, setInput] = useState({
    servicos: tarefa.servicos,
    produtos: tarefa.produtos,
    tipo_tarefa_id: tarefa.tipo_tarefa_id,
    tipo_tarefa: tarefa.tipo_tarefa,
  });

  useEffect(() => {
    setInput({
      servicos: tarefa.servicos,
      produtos: tarefa.produtos,
      tipo_tarefa_id: tarefa.tipo_tarefa_id,
      tipo_tarefa: tarefa.tipo_tarefa,
    });
  }, [tarefa, showEditTarefa]);

  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(input);
    if (input.tipo_tarefa_id !== "" && input.servicos !== "") {
      tarefa.produtos = input.produtos;
      tarefa.servicos = input.servicos;
      tarefa.tipo_tarefa_id = input.tipo_tarefa_id;
      tarefa.tipo_tarefa = input.tipo_tarefa;

      onHide();
      return;
    }
    alert("Por favor insira dados");
  };

  return (
    <>
      <LargeModal
        show={showEditTarefa}
        onHide={onHide}
        title="Editar Tarefa"
        otherButtons={buttons}
      >
        <DXTForm id="editarTarefa" onSubmit={handleSubmit} setError={setErrors}>
          <Form.Label>
            {" "}
            Tipo de tarefa
            <SelectTipoTarefa input={input}></SelectTipoTarefa>
          </Form.Label>
          <br></br>
          <Form.Label>
            {" "}
            Serviços
            <SelectServicos input={input}></SelectServicos>
          </Form.Label>{" "}
          <Form.Label>
            Produtos
            <SelectProdutos input={input}></SelectProdutos>
          </Form.Label>
        </DXTForm>
      </LargeModal>
    </>
  );
};

export default EditTarefaModal;
