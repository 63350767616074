import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const LargeModal = ({ show, onHide, title, otherButtons, children }) => {
  window.addEventListener("showSuccess", onHide);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {otherButtons}
          <Button onClick={onHide}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LargeModal;
