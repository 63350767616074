import { api } from "./configs/axiosConfig";
import ENDPOINTS from "./configs/endpoints";
export const MarcasAPI = {
  getMarcas: async function () {
    var response = await api.request({
      url: ENDPOINTS.MARCAS,
      method: "GET",
    });
    return response.data.data;
  },
};
export default MarcasAPI;
