import React from "react";
import { Form } from "react-bootstrap";

function DXTForm({ id, onSubmit, setError, children }) {
  window.addEventListener("showError", () => {
    var errors = JSON.parse(sessionStorage.getItem("errors"));

    setError(errors);
  });
  window.addEventListener("showSuccess", () => {
    var errors = JSON.parse(sessionStorage.getItem("errors"));

    setError(errors);
  });
  return (
    <>
      <Form noValidate id={id} onSubmit={onSubmit}>
        <div>{children}</div>
      </Form>
    </>
  );
}

export default DXTForm;
