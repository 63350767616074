import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TiposServicoAPI from "../api/tiposServicoAPI";

const SelectServicos = (input) => {
  const animatedComponents = makeAnimated();
  const [data, setData] = useState([]);
  const [defaults, setDefaults] = useState([]);

  useEffect(() => {
    TiposServicoAPI.getTiposServico().then((tiposServico) => {
      const newDefaults = [];
      for (const val of tiposServico) {
        newDefaults.push({ value: val.id, label: val.codigo });
      }
      setData(newDefaults);
    });
  }, [animatedComponents]);

  useEffect(() => {
    setValue();
  }, [data]);

  function setValue() {
    const newDefaults = [];
    for (const val of data) {
      if (
        input.input.servicos
          .map((servico) => servico.tipo_servico_id)
          .includes(val.value)
      ) {
        newDefaults.push(val);
      }
    }
    setDefaults(newDefaults);
  }

  function handleInput(select) {
    var values = select.map((x) => x);
    const servicos = [];
    for (const value of values) {
      const servico = {
        tipo_servico_id: value.value,
        tipo_servico: { codigo: value.label },
      };
      servicos.push(servico);
    }
    input.input.servicos = servicos;
    setValue();
  }
  return (
    <Select
      value={defaults}
      isMulti
      components={animatedComponents}
      name="servicos"
      options={data}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Selecione serviço(s)..."
      onChange={(value) => handleInput(value)}
    />
  );
};

export default SelectServicos;
