import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";
import Layout from "./components/Layout";
import Dashboard from "./components/pages/Dashboard";
import EmpregadosList from "./components/pages/EmpregadosList";
import ClientesList from "./components/pages/ClientesList";
import "./dxt.css";
import TiposTarefaList from "./components/pages/TiposTarefaList";
import ProdutosList from "./components/pages/ProdutosList";
import TiposServicoList from "./components/pages/TiposServicoList";
import OrdensServicoList from "./components/pages/OrdensServicoList";
import OrdemServico from "./components/pages/OrdemDeServico";
import VeiculosList from "./components/pages/VeiculosList";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/empregados" element={<EmpregadosList />} />
                <Route path="/clientes" element={<ClientesList />} />
                <Route path="/tiposTarefa" element={<TiposTarefaList />} />
                <Route path="/produtos" element={<ProdutosList />} />
                <Route path="/tiposServico" element={<TiposServicoList />} />
                <Route path="/ordensServico" element={<OrdensServicoList />} />
                <Route path="/ordemServico" element={<OrdemServico />} />
                <Route path="/veiculos" element={<VeiculosList />} />
              </Route>
              {/* Other routes */}
            </Routes>
          </Layout>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
